import {useRef,useEffect, useState} from 'react'; 
import styled from '@emotion/styled';   
import InfoIcon from '@mui/icons-material/Info'; 
import wechat_icon from '../asset/wechat_icon.png'; 
import alipay_icon from '../asset/alipay_icon.png'; 
import paypal_icon from '../asset/paypal_icon.png';  
import boleto_icon from '../asset/boleto_icon.png';
import gcash_icon from '../asset/gcash_icon.png';
import grappay_icon from '../asset/grappay_icon.png';
import qiwi_icon from '../asset/qiwi_icon.png';
import shoppay_icon from '../asset/shoppay_icon.png';
import wmlogo_icon from '../asset/wmlogo_icon.png';
import yandex_icon from '../asset/yandex_icon.png';
import yoomoney_icon from '../asset/yoomoney_icon.png';
import ovo_logo from '../asset/ovo_logo.png';
import qris_logo from '../asset/qris_logo.png';
import ToolTip from '@mui/material/Tooltip';
import knockhero from '../asset/knockhero.png';
import aw_logo from '../asset/aw_logo.jpg';
import GetGameName from '../utils/constants';
import { useSelector,useDispatch } from 'react-redux';
import {Navigate} from 'react-router-dom';
import user, {addCompletedOrders,addOrder,setPaymentPlatform} from '../store/user';
import QrCodeWithLogo from "qrcode-with-logos";
import alipay_mini from '../asset/alipay_mini.png';
import wechat_mini from '../asset/wechat_mini.png';
import russia_sbp_icon from '../asset/russia_sbp_icon.png';
import duitnow_logo from '../asset/duitnow_logo.jpg';
import vietqr_logo from '../asset/vietqr_logo.png';
import {Typography, 
    FormControl,
    InputAdornment,
    Card,
    CardContent,
    Grid,
    Button,
    Divider,
    Box,
    Table,
    TableCell,
    TextField,
    Snackbar,
    Alert, 
    TableRow,
    Paper,
    Fade,
    Link,
    CircularProgress,
    Hidden} from '@mui/material'; 
import GetUrl from '../utils/urls';
import FetchData from '../utils/fetch';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import useThrottle from '../utils/helper';

const MinAmount=60;
const MaxAmount=60000;
 

const DivWrapper= styled('div',{
    Name:'DivWrapper',
    Slot:"Root",
})(({theme,selected,active})=>{
    let st = {
        padding:'5px', 
        borderRadius:5, 
        border:'1px solid transparent',
        '&:hover, &:focus':{ 
            borderColor:theme.palette.neutral[300], 
            transform:'scale(1.1)',
            '@media (hover:none)':{
                backgroundColor:'transparent',
            },
        },
        textAlign:'center',
    } 
    if (selected){ 
        st.border='2px solid rgb(99, 102, 241)'; 
    } 

    if(!active){
        st.cursor = "no-drop";
    }
    return st
})

const getGameCover =(game)=>{
    if (game  == "aw"){
       return  (<img src={aw_logo} width={300}></img>)
    }else{
       return  <img src={knockhero} width={300}></img>
    }
}

const PaymentIcon = styled('img',{
    Name:"PaymentIcon",
    Slot:"Root",
})({
        width:100, 
    }
);

const PaymentNameText = styled("div",{
    Name:"PaymentNameText",
    Slot:"Root",
})({
    textAlign:"center",
    color:"#727f96",
    fontSize:"0.8em",
    fontWeight:400,
    marginBottom:0,
    paddingBottom:0
});

const PaymentPlatforms=[
      {
        icon:paypal_icon,
        name:"paypal",
        showName:"Paypal",
        active:true,
    },
    
    {
        icon:gcash_icon,
        name:"gcash_ph",
        showName:"GCash",
        active:true,
    },
    {
        icon:grappay_icon,
        name:"grabpay_ph",
        showName:"GrabPay",
        active:true,
    },
    {
        icon:russia_sbp_icon,
        name:"sbp_ru",
        showName:"Система быстрых платежей",
        active:true,
    },  
    {
        icon:wechat_icon,
        name:"wechat",
        showName:"微信支付",
        active:true,
    }, 
    {
        icon:alipay_icon,
        name:"alipay",
        showName:"支付宝",
        active:true,
    },
    {
        icon:ovo_logo,
        name:"ovo_id",
        showName:"OVO Wallet ",
        active:true,
    },
    {
        icon:qris_logo,
        name:"qris_id",
        showName:"InterActive QRIS",
        active:true,
    },
    {
        icon:duitnow_logo,
        name:"duitnow_my",
        showName:"DuitNow QR",
        active:true,
    }, 
    {
        icon:vietqr_logo,
        name:"vietqr_vn",
        showName:"VietQR",
        active:true,
    },
    {
        icon:shoppay_icon,
        name:"shopeepay_th",
        showName:"ShopeePay",
        active:true,
    },
    {
        icon:boleto_icon,
        name:"boleto_br",
        showName:"Boleto bancário",
        active:true,
    },

    {
        icon:yandex_icon,
        name:"yamoney",
        showName:"Yandex",
        active:false,
    },
     {
        icon:wmlogo_icon,
        name:"webmoney",
        showName:"WebMoney",
        active:false,
    }, 
    {
        icon:qiwi_icon,
        name:"qiwi",
        showName:"QIWI Кошелек",
        active:false,
    } ,
    {
        icon:yoomoney_icon,
        name:"yoomoney_ru",
        showName:"ЮMoney",
        active:false,
    }, 
   
]

export default function RechargePage(){ 
    const canvasRef = useRef();
    const imgRef = useRef(); 
    const theme = useTheme(); 
    const [currentAmount,setRechargeAmount] = useState(0)
    const userInfo = useSelector((state)=>state.user); 
    const {orders ,completeOrders,paymentPlatform}= userInfo;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [msgState, setState] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        msg:"请输入正确的金额",
      });
    const { vertical, horizontal, open,msg } = msgState;
    const [showBtn,setShowBtn]=useState(true);
    const dispatch = useDispatch();
    const [paymentUrl,setPaymentUrl]=useState("");
    const onPlatformChange=(platform,active)=>{
        if (active){ 
            dispatch(setPaymentPlatform({paymentPlatform:platform}));
        }
    }
    const checkOrderComplete=(id)=>{
        for (let cid in completeOrders){
            let co = completeOrders[cid]; 
            if (co.id == id){
                return true;
            }
        }
        return false;
    }
    useEffect(()=>{ 
        let timer = setInterval(()=>{
            for (let id in orders){
                let o= orders[id];
                if (checkOrderComplete(o.id)){
                    continue;
                }
              
                if (o && !o.completed){
                    let url= GetUrl(o.game);
                    let initReq = {
                        method:"GET",
                        url:url.host+ url.status(o.id)
                    };
                    FetchData(initReq,(data)=>{
                        if (data.status){ 
                            dispatch(addCompletedOrders({id:o.id,time:data.UpdatedAt}));
                            navigate(`/order/detail?id=${o.id}`);
                        }
                    });
                }
            }
        },20000);
        return ()=>{
                clearInterval(timer);
        }
    },[orders]);

    const makeMoneyValid = useThrottle(()=>{ 
        if (currentAmount < MinAmount){ 
            console.log(currentAmount);
            setRechargeAmount(MinAmount);
        }else if(currentAmount > MaxAmount) { 
            setRechargeAmount(MaxAmount);
        } },
        1000,[0]);

    const onMoneyChange=(event)=>{
        let iV = event.target.value;
        setRechargeAmount(iV);
        console.log(iV);
        makeMoneyValid();
    }

    const handleClose = () => { 
        setState({ ...msgState, open: false });
      };

    const onRecharge=()=>{
        if (currentAmount < MinAmount || !userInfo.steamId  || !userInfo.game ){
            setState({ ...msgState, open: true ,msg:theme.cusLocale.data["paymentMethodWarnText2"]});
            return;
        }

        if (!paymentPlatform){
            setState({ ...msgState, open: true ,msg:theme.cusLocale.data["paymentMethodWarnText1"]});
            return;
        }
        if (currentAmount >= MinAmount){
            let urlInfo = GetUrl(userInfo.game);
            let reqParam = {
                method:"POST",
                data:{steamId:userInfo.steamId,amount:parseInt(currentAmount)},
                url:urlInfo.host + urlInfo.recharge,
            }
            FetchData(reqParam,(data)=>{
                setLoading(true);
                setShowBtn(false);
                if (data.status){  
                    let reqParam2 = {
                        method:"GET",
                        data:{steamId:userInfo.steamId,amount:parseInt(currentAmount)},
                    }

                    if (paymentPlatform == "wechat"){
                        reqParam2.url = urlInfo.host + urlInfo.wechat(data.order);
                    }else if (paymentPlatform == "alipay"){   
                        reqParam2.url = urlInfo.host + urlInfo.alipay(data.order);
                    }else if (paymentPlatform == "paypal"){
                        reqParam2.url = urlInfo.host + urlInfo.paypal(data.order);
                    }else{
                        reqParam2.url = urlInfo.host + urlInfo.payssion(data.order,paymentPlatform);
                    }

                    FetchData(reqParam2,(data2)=>{ 
                       if (paymentPlatform == "wechat"){
                        let qrcode = new QrCodeWithLogo({
                            canvas: canvasRef.current,
                            content: data2.qrCode,
                            width: 200, 
                            image: imgRef.current,
                            logo: {
                              src: wechat_mini,
                              logoSize: 0.15
                            }
                          }); 
                        qrcode.toCanvas().then(()=>{console.log("to canvas ok");});
                       }else if (paymentPlatform == "alipay"){
                        let qrcode = new QrCodeWithLogo({
                            canvas: canvasRef.current,
                            content: data2.qrCode,
                            width: 200, 
                            image: imgRef.current,
                            logo: {
                              src: alipay_mini,
                              logoSize: 0.15
                            }
                          });
                        qrcode.toCanvas().then(()=>{console.log("to canvas ok");});
                       }else {
                            if (data2.url != ""){
                                setPaymentUrl(data2.url);
                                window.open(data2.url);
                            }
                       }
                       setLoading(false);
                       dispatch(addOrder({id:data.order,game:userInfo.game,platform:paymentPlatform,amount:currentAmount,time:moment().unix()}))
                    })
                }
            })
        }
    }

    if (!userInfo.steamId){
        console.log("navigate");
        return (<Navigate to="/" replace={true}></Navigate>);
    }

    return ( 
        <Box sx={{ flexGrow: 1, marginTop:'10px'}}>
            <Grid container spacing={1} justifyContent="center" >
                <Hidden mdDown>
                    <Grid item md={3} lg={3} xl={3} textAlign={"right"} > 
                    { getGameCover(userInfo.game)}    
                    </Grid>
                </Hidden> 
              
                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}> 
                <Card>
                     <CardContent>
                    <Typography gutterBottom variant="h6">
                       {theme.cusLocale.data["onlineRecharge"]}
                    </Typography>
                    <Table>
                        <tbody>
                            <TableRow>
                                <TableCell sx={{color:"gray"}}>{theme.cusLocale.data["game"]}</TableCell><TableCell sx={{textAlign:"end"}} >{theme.cusLocale.data[GetGameName(userInfo.game)]}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{color:"gray"}}>{theme.cusLocale.data["name"]}</TableCell><TableCell sx={{textAlign:"end"}} >{userInfo.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{color:"gray"}}>SteamID</TableCell><TableCell sx={{textAlign:"end"}}>{userInfo.steamId}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{theme.cusLocale.data["gamemoney"]}
                                  <div  > 
                                    <span><InfoIcon color="primary" sx={{verticalAlign:'bottom'}}/></span> 
                                  <span><Typography color="gray" component="span" sx={{fontSize:"0.7em"}}>{theme.cusLocale.data["momeyHelp"]}</Typography></span>
                                  </div>
                                </TableCell>
                                <TableCell sx={{textAlign:"end"}} >    
                                <FormControl sx={{ m: 1}} variant="outlined">  
                                    <TextField 
                                    id="money"
                                    type="number"
                                    inputProps={{
                                        step:MinAmount,
                                    }}
                                    value={currentAmount}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{theme.cusLocale.data["yuanshi"]}</InputAdornment>,
                                    }}
                                    variant="standard"
                                    onChange={onMoneyChange}
                                />
                                </FormControl>
                                <div>
                                    {
                                        theme.cusLocale.lan == "zh-CN"?
                                        <div><Typography variant='small' color="gray" sx={{px:1}}>{theme.cusLocale.data["money"]}:</Typography>
                                        <Typography   variant='small' color="#6366f1" sx={{fontWeight:700}}>CNY￥{(currentAmount/10).toFixed(2)}</Typography>
                                        </div>:
                                        <div><Typography variant='small' color="gray" sx={{px:1}}>{theme.cusLocale.data["money"]}:</Typography>
                                        <Typography   variant='small' color="#6366f1" sx={{fontWeight:700}}>USD${(currentAmount/60).toFixed(2)}</Typography>
                                        </div>
                                    }
                                    
                                </div>
                                </TableCell>
                            </TableRow>
                            </tbody>
                    </Table>
                    <Divider/>
                   
                    <Typography gutterBottom variant="h6" sx={{my:3,}}>
                             {theme.cusLocale.data["paymentMethod"]}
                        </Typography> 
                            <Box sx={{ m: 2 }}>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}> 
                                    {
                                        PaymentPlatforms.map((p,i)=>(
                                            <Grid sx={{display:"flex",flexDirection:"column",justifyContent:"space-between"}}item xs={2} sm={4} md={4} key={i}>
                                            <DivWrapper onClick={()=>{onPlatformChange(p.name,p.active);}} active={p.active} selected={p.name==paymentPlatform}>
                                                <PaymentIcon src={p.icon}>
                                            </PaymentIcon> 
                                            </DivWrapper>
                                            <PaymentNameText>{p.showName}</PaymentNameText>
                                            </Grid>
                                        ))
                                    } 
                                </Grid>
                            </Box>
                    </CardContent>
                    </Card>
                </Grid> 
                <Grid item xs={12} sm={12} md={3} lg={3} xl={4} alignItems="center" justifyContent="space-evenly" >
                    <Grid>
                        {showBtn &&  <Button variant='contained' size="large" onClick={onRecharge} hide>{
                            theme.cusLocale.data["conformBtn"]
                        }</Button>}
                    </Grid>
                    <Grid>
                        <Paper sx={{p:1}}>
                        {paymentUrl&&(<Link target="_blank" rel="noopener" href={paymentUrl} variant="body2">
                                {paymentUrl}
                            </Link>)
                            }
                            <Box sx={{ height: 40 }}>
                                    <Fade
                                    in={loading}
                                    style={{
                                        transitionDelay: loading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                    >
                                    <CircularProgress />
                                    </Fade>
                                </Box>
                            <canvas id="canvas" ref={canvasRef} style={{width:300}}></canvas>
                            <img src="" alt="" id="image" ref={imgRef}/>  
                         </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar   anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {msg}
            </Alert>
        </Snackbar>
        </Box>
    );
};