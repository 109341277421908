const AtomicWarUrl={
host:"https://pay.jyzdianjing.com/sr", 
// host:"https://test.atomicgaming.net/pay/sr", 
    player:(steamId)=>`/order/player?steamId=${steamId}`,
    recharge:"/order/recharge",
    status:(orderId)=>`/order/status?orderId=${orderId}`,
    wechat:(orderId)=>`/wechatpay/do?orderId=${orderId}`,
    alipay:(orderId)=>`/alipay/do?orderId=${orderId}`,
    paypal:(orderId)=>`/paypal/do?orderId=${orderId}`,
    payssion:(orderId,pmId)=>`/payssion/do?orderId=${orderId}&pm=${pmId}`,
}

function GetUrl(game){
    let gameUrl = {};
    if (game == "aw"){
        gameUrl = AtomicWarUrl;
    }
    return gameUrl
}

export default GetUrl;